export const __SPEEDY_SSR_DATA__ = '__SPEEDY_SSR_DATA__';

type Obj = Record<string, any>;

// https://github.com/vercel/next.js/blob/de54ad895c075761723f8de5c8e7041ec04503e9/packages/next/types/index.d.ts#L165
export type GetServerData<ServerData extends Obj = Obj> = (
  getServerDataContext: GetServerDataContext
) => Promise<GetServerDataResult<ServerData>> | GetServerDataResult<ServerData>;

export type GetServerDataContext = {
  pathname: string;
  query: Obj;
};

export type GetServerDataResult<ServerData extends Obj = Obj> = ServerData & {
  // extra built-in data field
};

export const defineGetServerData = <ServerData extends Obj = Obj>(getServerData: GetServerData<ServerData>) =>
  getServerData;
